import { nextTick, reactive, toRefs, watch } from 'vue'
import { Pagination } from '@/types/Pagination'

export default function usePagination(pageChangeCallback?: (pagination: Pagination) => void) {
  const pagination = reactive<Pagination>({
    pageSizes: [15, 30, 50, 100, 500, 1000, 3000, 5000],
    layout: "total, sizes, prev, pager, next, jumper",
    pageSize: 15,
    currentPage: 1,
    total: 0,
    pageOffset: 1,
    onSizeChange: () => 1,
    onCurrentChange: () => 1,
    setCallback: () => 1
  })

  // element plus dialog bug, 暂且先这样处理
  watch(() => pagination.total, () => {
    const a = pagination.pageSize
    pagination.pageSize = 1
    nextTick(() => pagination.pageSize = a)
  })

  let callback = pageChangeCallback

  pagination.setCallback = cb => callback = cb

  pagination.onSizeChange = (val: number) => {
    let num = pagination.pageSize * pagination.currentPage
    num = num < pagination.total ? num : pagination.total
    pagination.currentPage = Math.floor((num - 1) / val) + 1
    pagination.pageSize = val
    if (pagination.currentPage < 1) pagination.currentPage = 1
    pagination.pageOffset = (pagination.currentPage - 1) * pagination.pageSize + 1
    callback && callback(pagination)
  }

  pagination.onCurrentChange = (page: number) => {
    pagination.currentPage = page
    pagination.pageOffset = (pagination.currentPage - 1) * pagination.pageSize + 1
    callback && callback(pagination)
  }

  return reactive({
    ...toRefs(pagination),
  })
}