
import { watch, ref, onMounted, nextTick, getCurrentInstance, PropType, defineComponent, onUpdated} from 'vue'
import { TableColumns, ColumnConfig } from '@/types/BasicComponent'
import { callIfFunction, renderButton } from './utils'

export default defineComponent({
  props: {
    tableColumns: {
      type: Array as PropType<TableColumns>,
      default: []
    },
    tableData: {
      type: Array,
      default: []
    },
    tableHeight: {
      type: [String, Number],
      default: '600px'
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    summaryMethod: {
      type: Function,
    },
    rowClassName: {
      type: [Function, String],
      default: ''
    },
    headerRowClassName:{
      type: String,
      default: ''
    },
    rowKey: String,
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    defaultSort: {
      type: Object,
      default: {}
    },
    spanMethod: {
      type: Function
    },
    treeProps: {
      type: Object
    }
  },
  emits: ['selection-change', 'select-all', 'select', 'row-click', 'sort-change'],
  setup(props, {emit, slots}) {
    const autoWidthList = ref<number[]>([])
    const tableRef = ref()

    const setButtonAutoWidth = () => {
      const resultList: number[] = []
      Array.from(document.querySelectorAll('.operation-wrapper')).forEach(elem => {
        const childList = Array.from(elem.childNodes) as HTMLElement[]
        let childWidthSum = 0
        childList.forEach(childElem => childWidthSum += childElem.clientWidth ? childElem.clientWidth + 20 : 0)
        resultList.push(childWidthSum + 10)
      })
      autoWidthList.value = resultList
    }
    // 计算按钮列的宽度
    onUpdated(() => {
      setButtonAutoWidth()
    })
    
    watch(() => [...props.tableData], () => {
      nextTick(() => {
        // 解决有时候滚动条消失
        tableRef.value.doLayout()
        const bodyWrapper = tableRef.value.$el.querySelector('.el-table__body-wrapper')
        // 解决右侧固定栏边框消失
        bodyWrapper.scrollLeft = 1
        nextTick(() => bodyWrapper.scrollLeft = 0)

        setButtonAutoWidth()
      })
    })

    // 暴露el-table函数给父组件
    const instance = getCurrentInstance() as any
    onMounted(() => {
      instance.ctx.toggleRowSelection = tableRef.value.toggleRowSelection
      instance.ctx.doLayout = tableRef.value.doLayout
      instance.ctx.tableRef = tableRef
    })

    onUpdated(() => tableRef.value.clearSelection())

    return () => {
      let autoWidthCounter = 0
      return (
        <el-table
          {...props}
          class="basic-table"
          ref={tableRef}
          data={props.tableData}
          border
          height={props.tableHeight}
          highlight-current-row
          v-loading={props.tableLoading}
          show-summary={props.showSummary || props.summaryMethod? true: false}
          summary-method={props.summaryMethod}
          rowKey={props.rowKey}
          onSelect={(selection: any, row: any) => emit('select', selection, row)}
          onSelectionChange={(selection: any) => emit('selection-change', selection)}
          onSelectAll={(selection: any) => emit('select-all', selection)}
          onSortChange={(val: any) => emit('sort-change', val)}
          row-class-name={props.rowClassName}
          header-row-class-name={props.headerRowClassName}
          default-expand-all={props.defaultExpandAll}
          defaultSort={props.defaultSort}
          lazy={true}
          span-method={props.spanMethod}
          tree-props={props.treeProps}
          v-slots={{
            default: () => {
              autoWidthCounter = -1
              return slots.default? slots.default(): props.tableColumns.map(columnConfig => {

                if (!callIfFunction(columnConfig.show, true)) {
                  return
                }

                if (columnConfig.type === 'button') autoWidthCounter ++

                let nativeType: string | undefined = undefined
                if (['selection', 'index', 'expand'].includes(columnConfig.type || '')) {
                  nativeType = columnConfig.type
                }

                const renderColumn = (columnConfig: ColumnConfig) => (
                  <el-table-column
                    min-width={columnConfig.minWidth || ''}
                    width={columnConfig.width || (!columnConfig.minWidth && columnConfig.type === 'button' && autoWidthList.value[autoWidthCounter]) || ''}
                    label={nativeType === 'expand'? undefined: columnConfig.label}
                    prop={columnConfig.prop}
                    align={columnConfig.align}
                    fixed={columnConfig.fixed}
                    type={nativeType}
                    reserve-selection={columnConfig.reserveSelection}
                    sortable={columnConfig.sortable}
                    selectable={columnConfig.selectable}
                    index={columnConfig.index}
                    class-name={columnConfig.className}
                    v-slots={{
                      default: ['selection', 'index' /**type为这两个类型的话不需要插槽 */].includes(nativeType as string)? undefined: (scope: any) => {
                        // 多级表头
                        if (columnConfig.columns) {
                          const columnRenderList = []
                          for (let column of columnConfig.columns) {
                            columnRenderList.push(renderColumn(column))
                          }
                          return columnRenderList
                        } else if (columnConfig.type === 'link') {
                          return (
                            <span
                              class="link-type"
                              onClick={() => columnConfig.onClick?.(scope)}
                            >{scope.row[columnConfig.prop as string]}</span>
                          )
                        } else if (columnConfig.type === 'button') {
                          return (
                            <div class="operation-wrapper">
                              {columnConfig.buttons.map(buttonConfig => renderButton(buttonConfig, scope))}
                            </div>
                          )
                        } else if (columnConfig.type === 'status') {
                          if (columnConfig.statusType) {
                            const match = columnConfig.statusType.find(cfg => cfg.value === scope.row[columnConfig.prop as string])
                            if (!match) return <div></div>
                            if (!match.type) {
                              return <span>{match.label}</span>
                            }
                            return (
                              <el-tag
                                type={match.type}
                                effect={columnConfig.effect? columnConfig.effect: 'plain'}
                                size={columnConfig.effect? columnConfig.effect: 'medium'}
                                v-slots={{
                                  default: () => match.label
                                }}
                              ></el-tag>
                            )
                          }
                        } else if (columnConfig.type === 'render' || columnConfig.type === 'expand') {
                          return columnConfig.render(scope)
                        } else if (columnConfig.prop) {
                          return <span>{scope.row[columnConfig.prop]}</span>
                        }
                      },
                    }}
                  ></el-table-column>
                )
                return renderColumn(columnConfig)
              })
            }
          }}
        >
        </el-table>
      )
    }
  },
})
