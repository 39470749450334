
import { onMounted, ref, watch, computed, getCurrentInstance, Transition, PropType, defineComponent } from 'vue'
import useTableAdaptiveHeight from '@/utils/composables/useTableAdaptiveHeight'
import BasicTable from '@/components/BasicPageLayout/BasicTable.vue'
import BasicInputGroup from '@/components/BasicPageLayout/BasicInputGroup.vue'
import { Buttons, Inputs, TableColumns, RenderFunc, InputConfig } from '@/types/BasicComponent'
import { authFilter, callIfFunction, dropdownLayout, renderButton } from './utils'

export default defineComponent({
  components: {
    BasicTable,
    BasicInputGroup
  },
  props: {
    searchInputs: {
      type: Array as PropType<Inputs>,
      default: []
    },
    searchButtons: {
      type: Array as PropType<Buttons>,
      default: []
    },
    tableColumns: {
      type: Array as PropType<TableColumns>,
      default: []
    },
    tableData: {
      type: Array as PropType<AnyArray>,
      default: []
    },
    tableLoading: {
      type: Boolean
    },
    tableRowKey: {
      type: String,
      default: ''
    },
    tableSpanMethod: {
      type: Function,
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    summaryMethod: {
      type: Function
    },
    pagination: {
      type: Object
    },
    tableRowClassName: {
      type: [Function, String],
      default: ''
    },
    tableDefaultExpandAll: {
      type: Boolean,
      default: false
    },
    tableDefaultSort: {
      type: Object,
      default: {}
    },
    showSelection: {
      type: Boolean
    },
    tableTreeProps: {
      type: Object
    },
  },
  emits: ['selection-change', 'sort-change'],
  setup(props, {slots, emit}) {
    const pagination = props.pagination
    const tableContainerRef = ref()
    const tableRef = ref()
    const tableHeight = useTableAdaptiveHeight(tableContainerRef)
    
    const validTableColumns = computed(() => {
      const validColumns = authFilter(props.tableColumns)
      for (let column of validColumns) {
        if (column.type === 'button') {
          column.buttons = authFilter(column.buttons)
        }
      }
      return validColumns
    })

    const validSearchInputs = computed(() => authFilter(props.searchInputs))

    const validSearchButtons = computed(() => authFilter(props.searchButtons))

    // 暴露变量给父组件
    const instance: any = getCurrentInstance()
    onMounted(() => {
      instance.ctx.doLayout = tableRef.value.doLayout
    })

    const tableRender = ref(true)
    watch(() => props.tableColumns, () => {
      tableRender.value = false
      setTimeout(() => tableRender.value = true, 250)
    })
    // @ts-ignore
    const inputGroupRender: RenderFunc<[JSX.Element, InputConfig]> = (itemRenderResult, itemConfig: any) => (
      <div class="filter-item">
        {itemConfig.label && <span>{itemConfig.label}</span>}
        {itemRenderResult}
      </div>
    )

    return () => {
      return (
        <div class="__basic-table-page">
          <div class="filter-container" style="padding-top: 0; padding-bottom: 0">
            {slots.header?.()}
          </div>
          <div 
            class="filter-container filter-container-flex" 
            style={{
              minWidth: '800px',
              display: (props.searchInputs.length === 0 && props.searchButtons.length === 0) && !slots.header? 'none': 'flex'
            }}>
            <div>
              <basic-input-group
                inputs={validSearchInputs.value}
                render={inputGroupRender}
              ></basic-input-group>
            </div>
            <div style="display: flex; justify-content: flex-end">
              {validSearchButtons.value.map(buttonConfig => <div class="filter-item">{renderButton(buttonConfig)}</div>)}
            </div>
          </div>
          <div style="overflow: hidden">
            {tableRender.value && (
              <div ref={tableContainerRef} class="table-container content-container">
                {slots.table
                  ? slots.table()
                  : (
                    <basic-table
                      ref={tableRef}
                      tableColumns={validTableColumns.value}
                      tableData={props.tableData}
                      tableHeight={tableHeight.value}
                      tableLoading={props.tableLoading}
                      showSummary={props.showSummary}
                      summaryMethod={props.summaryMethod}
                      showSelection={props.showSelection}
                      rowKey={props.tableRowKey}
                      onSelectionChange={(val: any) => emit('selection-change', val)}
                      onSortChange={(val: any) => emit('sort-change', val)}
                      rowClassName={props.tableRowClassName}
                      defaultExpandAll={props.tableDefaultExpandAll}
                      defaultSort={props.tableDefaultSort}
                      spanMethod={props.tableSpanMethod}
                      treeProps={props.tableTreeProps}
                    ></basic-table>
                  )
                }
                {slots.tableExtra?.()}
                <div style="background: white; display: flex; align-items: center">
                  {slots.paginationPrefix?.()}
                  {pagination && (
                    <el-pagination
                      onSizeChange={pagination.onSizeChange}  
                      onCurrentChange={pagination.onCurrentChange}
                      currentPage={pagination.currentPage}
                      pageSizes={pagination.pageSizes}
                      pageSize={pagination.pageSize}
                      layout={pagination.layout}
                      total={pagination.total}
                      background
                    ></el-pagination>
                  )}
                  {slots.paginationSuffix?.()}
                </div>
              </div>
            )}
          </div>
          <div>
            {slots.dialog?.()}
          </div>
        </div>
      )
    }
  }
})
