import { ref, watch, computed, nextTick, onActivated, getCurrentInstance, onDeactivated, ComponentInternalInstance, Ref } from 'vue'
import { debounce } from 'throttle-debounce';
import { useStore } from 'vuex';

let activeInstance: ComponentInternalInstance | null

export default function useTableAdaptiveHeight(tableContainerRef: Ref<Element>, minHeight = 300) {

  const store = useStore()

  let tableHeight = ref(0)

  const calcAdaptiveHeight = () => {
    let containerElem = tableContainerRef
    const bodyHeight = document.body.offsetHeight
    const containerRectTop = containerElem.value.getBoundingClientRect().top
    const bottomPaddingHeight = 0
    const extraElemHeight = 
    Array
      .from(containerElem.value.childNodes)
      .reduce((heightSum: number, curElem: any) => {
        if (typeof curElem.getBoundingClientRect !== 'function') return heightSum
        return heightSum + curElem.getBoundingClientRect().height
      }
      , 0)
    - (containerElem.value.querySelector('.el-table') as HTMLElement).getBoundingClientRect().height
    return bodyHeight - containerRectTop - extraElemHeight - bottomPaddingHeight
  }

  const setTableHeight = () => tableHeight.value = calcAdaptiveHeight()
  const setTableHeightDebounce = debounce(500, () => tableHeight.value = calcAdaptiveHeight())

  onActivated(() => {
    activeInstance = getCurrentInstance()
    window.addEventListener('resize', setTableHeight)
    nextTick(setTableHeight)
  })

  onDeactivated(() => {
    activeInstance = null
    window.removeEventListener('resize', setTableHeight)
  })

  watch(tableHeight, val => {
    if (val < minHeight) tableHeight.value = minHeight
  })

  const sidebarOpen = computed(() => store.getters.sidebar.opened)

  watch(sidebarOpen, setTableHeightDebounce)

  return tableHeight
}